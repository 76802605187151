import {on} from 'delegated-events'

on('click', '.js-private-repo-creation-allowed', function (event) {
  const privateAllowedCheckbox = event.currentTarget
  if (privateAllowedCheckbox instanceof HTMLInputElement) {
    const privateOnlyAllowed = privateAllowedCheckbox.getAttribute('data-private-only-policy-allowed') === 'true'
    const publicAllowedCheckbox = document.querySelector<HTMLInputElement>('.js-public-repo-creation-allowed')!
    const publicDisabledDocLink = document.querySelector<HTMLElement>('.js-public-disabled-doc-link')!
    if (!privateOnlyAllowed) {
      if (!privateAllowedCheckbox.checked) {
        publicAllowedCheckbox.disabled = false
        publicDisabledDocLink.hidden = true
      } else {
        publicAllowedCheckbox.disabled = true
        publicAllowedCheckbox.checked = true
        publicDisabledDocLink.hidden = false
      }
    }
  }
})

on('click', '.js-private-repo-forking-allowed', function (event) {
  const privateRepoForkingAllowedCheckbox = event.currentTarget as HTMLInputElement
  const forkingPolicyList = document.querySelector<HTMLElement>('.js-org-private-repo-forking-policies')

  if (!privateRepoForkingAllowedCheckbox || !forkingPolicyList) return

  forkingPolicyList.hidden = !privateRepoForkingAllowedCheckbox.checked
})
