import {dialog} from '@github-ui/details-dialog'
import {on} from 'delegated-events'
import {requestSubmit} from '@github-ui/form-utils'

function samlForm(): HTMLFormElement {
  return document.querySelector<HTMLFormElement>('.js-saml-provider-settings-form')!
}

function samlFormInputs(): Element | null {
  return samlForm().querySelector('.js-saml-form-inputs')
}

function samlCurrentlyEnabled(): boolean {
  return document.querySelector<HTMLInputElement>('.js-org-saml-currently-enabled')!.value === '1'
}

function confirmEnforceSamlHiddenField(): HTMLInputElement {
  return document.querySelector<HTMLInputElement>('.js-org-saml-confirm-enforcement-hidden')!
}

function samlPreviouslyEnforced(): boolean {
  return document.querySelector<HTMLInputElement>('.js-org-saml-previously-enforced')!.value === '1'
}

function samlEnforcementUnconfirmed(): boolean {
  return confirmEnforceSamlHiddenField().value === '0'
}

function orgHasUnlinkedSamlMembers(): boolean {
  return document.querySelector<HTMLInputElement>('.js-org-has-unlinked-saml-members')!.value === '1'
}

function show(target: Element | null) {
  if (!target) return
  /* eslint-disable-next-line github/no-d-none */
  target.classList.remove('d-none')
}

function hide(target: Element | null) {
  if (!target) return
  /* eslint-disable-next-line github/no-d-none */
  target.classList.add('d-none')
}

// Does the user intend to enable SAML?
function enablingSaml(): boolean {
  return document.querySelector<HTMLInputElement>('.js-org-enable-saml')!.checked
}

// Does the user intend to disable SAML?
function disablingSaml(): boolean {
  return samlCurrentlyEnabled() && !enablingSaml()
}

// The HTMLInputElement representing which form button was clicked by the user.
// This element is added to the page by the github/remote-submit.js module.
function submitAction(): HTMLInputElement {
  return document.querySelector<HTMLInputElement>('.js-submit-button-value')!
}

// Did the user click the "Test SAML Configuration" button?
function testingSettings(): boolean {
  return submitAction().name === 'test_settings'
}

// Did the user click the "Save" button?
function savingSettings(): boolean {
  return submitAction().name === 'save_settings'
}

// Is the user trying to enforce SAML for this organization?
function enforcingSaml(): boolean {
  return document.querySelector<HTMLInputElement>('.js-org-saml-enforce')!.checked
}

// Should the user be prompted to confirm enforcement of this organization?
//
// This is true when SAML is enforced for an organization that has
// members who have not yet SSO'd using the organization's IdP.
function enforcingWithNonSsoMembers(): boolean {
  return enforcingSaml() && samlEnforcementUnconfirmed() && !samlPreviouslyEnforced() && orgHasUnlinkedSamlMembers()
}

// Show a warning/confirmation dialog when disabling SAML.
async function showDisableSamlWarning() {
  const confirmation = await dialog({
    content: document.querySelector<HTMLTemplateElement>('#disable-saml-confirmation')!.content.cloneNode(true),
  })

  confirmation.addEventListener('dialog:remove', restoreState)
}

// Show a warning/confirmation dialog when enforcing SAML.
function showEnforcementConfirmationPrompt() {
  dialog({content: document.querySelector<HTMLTemplateElement>('#enforce-saml-confirmation')!.content.cloneNode(true)})
}

// Submit the form using the native .submit() function.
// Use this when you *really* want to submit the form and avoid clashes
// with the github `submit()` helper.
function submitSamlForm() {
  samlForm().submit()
}

// Show the SAML provider settings form when the "Enable SAML" checkbox is
// checked.
on('click', '.js-org-enable-saml', function (event) {
  if ((event.currentTarget as HTMLInputElement).checked) {
    show(samlFormInputs())
  } else {
    hide(samlFormInputs())
  }
})

// Explicitly controls the flow of events required to submit the security
// settings form in the correct state:
// -  Prevent the default 'submit' event from firing too early
// -  Set a hidden input value to distinguish between
//    "Test settings" and "Save" buttons on the server
// -  Disable the save changes warning, because we're about to submit the form
// -  Fire the 'submit' event on the form
on('click', '.js-saml-submit', function (event) {
  event.preventDefault()
  const button = event.currentTarget as HTMLButtonElement
  requestSubmit(samlForm(), button)
})

// When the admin confirms SAML SSO should be enforced we flip a hidden field
// to allow the form submission to happen.
on('click', '.js-org-saml-confirm-enforce-button', function () {
  confirmEnforceSamlHiddenField().value = 'true'
  requestSubmit(samlForm())
})

// Handle submitting the form.
// Depending on the user's choices, we handle these states:
//
// 1. Testing SAML settings
// 2. Saving SAML settings:
//    2.1 Disabling SAML:
//     - Show a warning and prompt for confirmation
//    2.2 Enforcing SAML:
//      - Show a warning if any existing members are not SSO'd
//    2.3 Submit the form
//
on('submit', '.js-saml-provider-settings-form', function (event) {
  event.preventDefault()
  if (testingSettings()) {
    submitSamlForm()
  } else if (savingSettings()) {
    if (disablingSaml()) {
      showDisableSamlWarning()
    } else if (enforcingWithNonSsoMembers()) {
      showEnforcementConfirmationPrompt()
    } else {
      submitSamlForm()
    }
  }
})

// Restore the "enabled" state of the UI, if the user cancels the "disable"
// dialog
function restoreState() {
  const checkbox = document.querySelector('.js-org-enable-saml')
  if (checkbox && checkbox instanceof HTMLInputElement) {
    checkbox.checked = true
    show(samlFormInputs())
  }
}

on('click', '.js-saml-session-length-checkbox', function () {
  const checked = document.querySelector<HTMLInputElement>('.js-saml-session-length-checkbox')!.checked

  const inputWrapper = document.querySelector<HTMLInputElement>('.js-saml-session-length-input-wrapper')!
  const input = document.querySelector<HTMLInputElement>('.js-saml-session-length-input')!
  inputWrapper.hidden = checked ? false : true
  if (!checked) {
    input.value = ''
  }
})
