import AutocompleteElement from '@github/auto-complete-element'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {on} from 'delegated-events'

function setLoading(container: Element, isLoading: boolean) {
  container.querySelector<HTMLElement>('.js-add-child-team-loading-state')!.hidden = !isLoading
  container.querySelector<HTMLElement>('.js-add-child-team-modal-content')!.hidden = isLoading
}

on('auto-complete-change', '.js-add-child-team-completer', async function ({target: completer}) {
  if (!(completer instanceof AutocompleteElement)) return
  if (!completer.value) return

  const container = completer.closest<HTMLElement>('.js-add-team-container')!
  setLoading(container, true)

  const form = completer.closest<HTMLElement>('.js-add-child-team-form')!
  const url = form.getAttribute('data-change-parent-summary-path')!
  const fragment = await fetchSafeDocumentFragment(document, url)
  form.querySelector<HTMLElement>('.js-add-team-search')!.hidden = true
  form.querySelector<HTMLElement>('.js-add-team-warning')!.append(fragment)
  setLoading(container, false)
})

on('submit', '.js-add-child-team-form', function (event) {
  const form = event.currentTarget
  const {value: completerValue} = form.querySelector<AutocompleteElement>('.js-add-child-team-completer')!

  if (!completerValue) {
    event.preventDefault()
  }
})

on('details-dialog-close', '.js-add-team-dialog', function (event) {
  const dialog = event.currentTarget
  const container = dialog.querySelector<HTMLElement>('.js-add-team-container')!

  dialog.querySelector<HTMLElement>('.js-add-team-search')!.hidden = false
  dialog.querySelector<HTMLElement>('.js-add-team-warning')!.textContent = ''
  setLoading(container, false)
})
