import {fire, on} from 'delegated-events'

// Replace the list of PATs that have access to the organization
// when a new 'owner' is selected.
on('click', '.js-pat-owner-search-filter', async function (event) {
  event.preventDefault()

  const currentTarget = event.currentTarget as HTMLInputElement
  const inputField = document.querySelector<HTMLInputElement>('input.auto-search-input')!

  const filterOn = currentTarget.name
  const filter = currentTarget.value

  // Remove any existing filters on the opened dropdown.
  const currentValue = inputField.value

  // Regex allows a-zA-Z0-9 chars, underscores, and hyphens.
  const regex = new RegExp(`${filterOn}:(?:[a-zA-Z0-9]|-|_|)+`)
  const currentFilters = currentValue.toString().trim().replace(regex, '')

  // Only add the new filter if a value was provided
  let inputFieldValue = `${currentFilters}`
  if (filter) inputFieldValue = `${inputFieldValue} ${filterOn}:${filter}`

  inputField.value = inputFieldValue.replace(/\s\s/, ' ').trim()
  inputField.focus()
  fire(inputField, 'input')
})
