import type AutocompleteElement from '@github/auto-complete-element'
import {on} from 'delegated-events'

on('auto-complete-change', '.js-team-project-completer', function (event) {
  const completer = event.target as AutocompleteElement
  const form = completer.closest<HTMLFormElement>('form')!
  const idInput = form.elements.namedItem('project_id') as HTMLInputElement
  const field = form.querySelector<HTMLInputElement>('.js-team-project-complete-field')!

  if (completer.value) {
    const {name, id} = JSON.parse(completer.value)
    idInput.value = id
    field.value = name
  } else {
    idInput.value = ''
  }
})
