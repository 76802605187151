import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {onKey} from '@github-ui/onfocus'
import {sendHydroEvent} from '../hydro-tracking'

type Data = {new_price: string; selectors: {[key: string]: string}}

const TEAM_IDS = 'team_ids[]'
const ROLE = 'role'

const createHiddenInput = (name: string, value: string): HTMLInputElement => {
  const hiddenInput = document.createElement('input')
  hiddenInput.type = 'hidden'
  hiddenInput.name = name
  hiddenInput.value = value

  return hiddenInput
}

const getTeamIds = (checkboxSelector: string): string[] => {
  const params = new URLSearchParams(window.location.search)

  const teamIds = new Set(params.getAll(TEAM_IDS))

  const checkboxes = document.querySelectorAll<HTMLInputElement>(checkboxSelector)
  for (const cb of checkboxes) {
    if (cb.checked) {
      teamIds.add(cb.value)
    } else {
      teamIds.delete(cb.value)
    }
  }

  return [...teamIds]
}

const getPaginationLinks = (classSelector: string) => {
  return document.querySelectorAll<HTMLAnchorElement>(`${classSelector} > a`)
}

function addOrRemoveHiddenTeamIdInput(checkBox: HTMLInputElement, formSelector: string) {
  if (checkBox.checked) {
    for (const form of document.querySelectorAll<HTMLFormElement>(formSelector)) {
      form.appendChild(createHiddenInput(TEAM_IDS, checkBox.value))
    }
  } else {
    for (const hiddenInput of document.querySelectorAll<HTMLInputElement>(
      `input[type="hidden"][name="${TEAM_IDS}"][value="${checkBox.value}"]`,
    )) {
      hiddenInput.remove()
    }
  }
}

function updateTeamIdsOnPaginationLinks(paginationSelector: string, teamIdsSelector: string) {
  const teamIds = getTeamIds(teamIdsSelector)

  for (const link of getPaginationLinks(paginationSelector)) {
    const href = link.getAttribute('href')
    if (href) {
      const url = new URL(href, window.location.origin)
      const search = new URLSearchParams(url.search.slice(1))
      search.delete(TEAM_IDS)
      for (const id of teamIds) {
        search.append(TEAM_IDS, id)
      }
      url.search = search.toString()
      link.setAttribute('href', url.toString())
    }
  }
}

on('click', '.js-invitation-toggle-team', async function ({currentTarget}) {
  //update the url of each pagination link (next/prev) to include the set of selected team_ids
  updateTeamIdsOnPaginationLinks('.js-invitations-team-suggestions-pagination', '.js-invitation-toggle-team')

  //and finally update the set of ids in the form's hidden input
  const checkBox = currentTarget as HTMLInputElement
  addOrRemoveHiddenTeamIdInput(checkBox, 'form.js-org-team-suggestions')
})

on('click', '.js-invitation-radio-role', async function ({currentTarget}) {
  const radioButton = currentTarget as HTMLInputElement
  const role = radioButton.value

  //update the url of each pagination link (next/prev) to include the selected role
  for (const link of getPaginationLinks('.js-invitations-team-suggestions-pagination')) {
    const href = link.getAttribute('href')
    if (href) {
      const url = new URL(href, window.location.origin)
      const search = new URLSearchParams(url.search.slice(1))
      search.set('role', role)
      url.search = search.toString()
      link.setAttribute('href', url.toString())
    }
  }

  // remove the old role(s) if they are currently set on the forms
  for (const hiddenInput of document.querySelectorAll<HTMLInputElement>(`input[type="hidden"][name="${ROLE}"]`)) {
    hiddenInput.remove()
  }

  // and finally update the role in the form's that need the data
  for (const form of document.querySelectorAll<HTMLFormElement>('form.js-org-member-role')) {
    form.appendChild(createHiddenInput(ROLE, role))
  }
})

// On the orgs/invitations/reinstate page, syncs the currently selected method
// (reinstate or 'start fresh' -- via a radio <input>) of inviting someone to
// join the organization with the currently displayed submission <form>.
function syncReinstateSelection() {
  const formContainer = document.querySelector('.js-org-reinstate-forms')
  const options = document.querySelectorAll('.js-org-reinstate-option:checked')
  if (!formContainer || options.length !== 1) {
    return
  }

  const selectedFormId = options[0]!.getAttribute('data-form')!
  const forms = formContainer.getElementsByClassName('js-togglable-form')
  for (const form of forms) {
    /* eslint-disable-next-line github/no-d-none */
    form.classList.add('d-none')
  }

  const selectedForm = document.getElementById(selectedFormId)!
  /* eslint-disable-next-line github/no-d-none */
  selectedForm.classList.remove('d-none')
}

on('change', '.js-org-reinstate-option', syncReinstateSelection)
observe('.js-org-reinstate-forms', syncReinstateSelection)

observe('.js-member-suggestion', function () {
  const badges = Array.from(document.querySelectorAll('.js-badge')).map(
    el => el.querySelector<HTMLInputElement>('input')!.value,
  )
  if (badges.length === 0) {
    return
  }

  for (const suggestion of document.querySelectorAll<HTMLLIElement>('.js-member-suggestion')) {
    const suggestionValue = suggestion.getAttribute('data-autocomplete-value')!

    if (badges.includes(suggestionValue)) {
      suggestion.classList.add('disabled')
      suggestion.setAttribute('aria-disabled', 'true')

      const warningReason = suggestion.querySelector('.js-non-member-warning-reason')
      const warningAction = suggestion.querySelector('.js-non-member-warning-action')

      if (warningReason && warningAction) {
        for (const warning of [warningReason, warningAction]) {
          warning.textContent = warning.getAttribute('data-markup')!
          if (warning instanceof HTMLElement) warning.hidden = false
        }
      }
    }
  }
})

// When an option is chosen, create a badge and reset the input box.
on('combobox-commit', '.js-badge-container .js-new-org-members-complete-results', event => {
  const input = document.querySelector<HTMLInputElement>('.js-badge-input')!
  const badges = document.querySelector<HTMLElement>('.js-badges')!
  const result = event.target as Element
  const badgeTemplates = result.querySelectorAll<HTMLTemplateElement>('.js-badge-template')
  const existingBadgeValues = Array.from(document.querySelectorAll('.js-badge')).map(el => {
    return el.querySelector<HTMLInputElement>('input')!.value
  })

  for (const badgeTemplate of badgeTemplates) {
    const badgeClone = badgeTemplate.cloneNode(true) as HTMLTemplateElement
    const badge = badgeClone.content
    const badgeEmail = badge.querySelector<HTMLInputElement>('input')!.value
    // don't append email addresses (or usernames) if they have already been added
    if (!existingBadgeValues.includes(badgeEmail)) {
      badges.append(badge)
    }
  }

  // Update seat counter
  updateSeatCounter()

  instrumentAdd(event)

  input.value = ''
  input.setAttribute('placeholder', '')
})

// Toggles aria-pressed on badge when clicking on and off
on('click', '.js-badge-search-container .js-badge', event => {
  const node = event.currentTarget as HTMLButtonElement

  const ariaPressed = node.getAttribute('aria-pressed')!
  if (ariaPressed === 'true') {
    node.setAttribute('aria-pressed', 'false')
  } else {
    node.setAttribute('aria-pressed', 'true')
  }
})

// Disable the Invite button when closing the dialog, so it's not enabled if the dialog is
// opened back up. Loosely following what happens in toggleSubmitButton (autocomplete.ts)
on('details-dialog-close', '.js-invite-member-dialog', e => {
  const button = e.currentTarget.querySelector<HTMLButtonElement>('.js-auto-complete-button')!
  if (!button) return
  button.disabled = true
})

// Auto-select our hidden text field anytime we click on the container or a
// suggestion from the dropdown
on('click', '.js-badge-search-container', e => {
  const element = e.currentTarget as HTMLElement

  element.querySelector<HTMLInputElement>('input')!.focus()
})

on('click', '.js-badge-container .js-member-suggestion', () => {
  document.querySelector<HTMLInputElement>('.js-badge-input')!.focus()
})

// Removes badge one by one if none are selected
// If badges are selected then backspace removes the selected badges
onKey('keydown', '.js-badge-search-container .js-badge-input', (event: KeyboardEvent) => {
  // TODO: Refactor to use data-hotkey
  /* eslint eslint-comments/no-use: off */
  /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
  const input = event.target as HTMLInputElement

  const container = input.closest<HTMLElement>('.js-badge-search-container')!
  const selectedBadges = container.querySelectorAll<HTMLElement>('.js-badges .js-badge[aria-pressed="true"]')
  const allowPlanIncrease = container.getAttribute('data-allow-plan-increase')
  const notBackspaceAction = event.key !== 'Backspace' || input.value !== ''

  // If user has reached max seats then prevent any key besides backspace or space
  if (!allowPlanIncrease && maxSeatsUsed() && notBackspaceAction) {
    event.preventDefault()
    return
  } else if (notBackspaceAction) {
    return
  }

  if (selectedBadges.length !== 0) {
    for (const selectedTag of selectedBadges) {
      selectedTag.remove()
      instrumentRemove(selectedTag)
    }
  } else {
    const badges = container.querySelectorAll<HTMLElement>('.js-badges .js-badge')

    if (badges.length === 0) {
      return
    }

    const lastSpan = badges[badges.length - 1]!
    lastSpan.remove()
    instrumentRemove(lastSpan)
  }

  updateSeatCounter()
  /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
})

// How many seats have been used
function usedSeats() {
  const filledSeatsField = document.querySelector('.js-seats')
  if (!filledSeatsField) {
    return invitesCount()
  }

  const filledSeats = filledSeatsField.getAttribute('data-filled-seats')!
  return parseInt(filledSeats) + invitesCount()
}

// Updates all seat detail fields
function updateSeatCounter() {
  if (hideUpgradeMessage()) {
    return
  }

  updateFilledSeats()
  updateSeatsRemaining()
  updateMaxSeatsWarning()

  if (overMaxSeats()) {
    updateTotals()
  } else {
    toggleVisibility('.js-plan-no-change', false)
    toggleVisibility('.js-plan-changes', true)
    toggleVisibility('.js-complete-button', false)
    toggleVisibility('.js-invite-button', true)
  }
}

function updateFilledSeats() {
  const filledSeatsField = document.querySelector('.js-filled-seats')
  const newUsedSeats = usedSeats()

  if (filledSeatsField && newUsedSeats !== null) {
    filledSeatsField.textContent = newUsedSeats.toString()
  }
}

function hideUpgradeMessage() {
  const noInvites = invitesCount() <= 0

  if (noInvites) {
    toggleVisibility('.js-plan-no-change', true)
    toggleVisibility('.js-plan-changes', true)
    return true
  }

  return false
}

function updateSeatsRemaining() {
  const seatsRemainingEl = document.querySelector('.js-seats-remaining')

  if (seatsRemainingEl instanceof HTMLElement) {
    seatsRemainingEl.hidden = true
    const count = totalSeats() - usedSeats()
    const zeroField = document.querySelector<HTMLElement>('.js-seats-remaining-zero')!
    const singularField = document.querySelector<HTMLElement>('.js-seats-remaining-singular')!
    const pluralField = document.querySelector<HTMLElement>('.js-seats-remaining-plural')!
    zeroField.hidden = count !== 0
    singularField.hidden = count !== 1

    if (count > 1) {
      const updateSeatsRemainingPlural = document.querySelector<HTMLElement>('.js-seats-remaining-update-target')!
      updateSeatsRemainingPlural.textContent = String(count)
    }

    pluralField.hidden = count <= 1
  }
}

function updateMaxSeatsWarning() {
  const maxSeatsWarning = document.querySelector('.js-max-seats-warning')
  if (maxSeatsWarning instanceof HTMLElement) {
    maxSeatsWarning.hidden = !maxSeatsUsed()
  }
}

function toggleVisibility(selector: string, hidden: boolean) {
  const el = document.querySelector(selector)
  if (el instanceof HTMLElement) el.hidden = hidden
}

let previousController: AbortController | null = null
async function updateTotals() {
  const dataEl = document.querySelector('.js-seats')

  if (!dataEl) {
    return
  }

  const url = new URL(dataEl.getAttribute('data-url')!, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('seats', usedSeats().toString())
  url.search = params.toString()

  previousController?.abort()
  const {signal} = (previousController = new AbortController())
  let data: Data | null = null
  try {
    const response = await fetch(url.toString(), {signal, headers: {Accept: 'application/json'}})
    if (response.ok) data = (await response.json()) as Data
  } catch {
    // ignore network errors
  }
  if (signal.aborted) return
  if (!data) return

  // If there is no price change, skip message
  const newPrice = data.new_price
  const originalPrice = dataEl.getAttribute('data-original-price')

  if (originalPrice && newPrice === originalPrice) {
    toggleVisibility('.js-plan-no-change', true)
    return
  }

  updateNewSeatsText()

  const ref = data.selectors
  for (const selector in ref) {
    for (const element of document.querySelectorAll(selector)) {
      element.innerHTML = ref[selector]!
    }
  }

  toggleVisibility('.js-plan-no-change', true)
  toggleVisibility('.js-plan-changes', false)
  toggleVisibility('.js-complete-button', true)
  toggleVisibility('.js-invite-button', false)
}

function updateNewSeatsText() {
  const deltaField = document.querySelector<HTMLElement>('.js-new-seats')!
  const singularField = document.querySelector<HTMLElement>('.js-new-users-singular')!
  const pluralField = document.querySelector<HTMLElement>('.js-new-users-plural')!
  const count = invitesCount()
  singularField.hidden = count !== 1
  pluralField.hidden = count === 1
  deltaField.textContent = String(count)
}

function invitesCount() {
  const badges = document.querySelectorAll('.js-badge')
  return badges.length || 0
}

function totalSeats() {
  const dataField = document.querySelector('.js-seats')
  if (!dataField) {
    return 0
  }

  const totalSeatsValue = dataField.getAttribute('data-total-seats')!
  return parseInt(totalSeatsValue)
}

// Has user added the max amount of available seats
function maxSeatsUsed() {
  return totalSeats() === usedSeats()
}

// Has user gone over max seats
function overMaxSeats() {
  const currentUsedSeats = usedSeats()
  return totalSeats() < currentUsedSeats
}

function instrumentAdd(event: Event) {
  const badge = event.target as HTMLElement
  sendHydroEvent(badge)
}

function instrumentRemove(element: HTMLElement) {
  sendHydroEvent(element)
}
