import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {on} from 'delegated-events'

let locked = false

on('click', '.js-open-child-team', function (event) {
  if (locked) return
  locked = true

  const parentTeamSlug = event.currentTarget.getAttribute('data-parent-team-slug')
  if (!parentTeamSlug) return

  const childTeams = document.querySelectorAll(`.js-child-team[data-parent-team-slug="${parentTeamSlug}"]`)
  const teamRow = event.currentTarget.closest<HTMLElement>('.js-team-row')!

  if (teamRow.classList.contains('is-open')) {
    event.currentTarget.setAttribute('aria-expanded', 'false')
    hideTeams(teamRow, childTeams)
  } else {
    event.currentTarget.setAttribute('aria-expanded', 'true')
    const childTeamUrl = event.currentTarget.getAttribute('data-child-team-url')!
    showTeams(teamRow, childTeams, childTeamUrl)
  }
})

async function showTeams(teamRow: Element, childTeams: NodeListOf<Element>, url: string) {
  if (childTeams.length) {
    for (const childTeam of childTeams) {
      /* eslint-disable-next-line github/no-d-none */
      childTeam.classList.remove('d-none')
    }
    teamRow.classList.add('is-open')
    locked = false
  } else {
    const html = await fetchSafeDocumentFragment(document, url)
    teamRow.after(html)
    teamRow.classList.add('is-open')
    locked = false
  }
}

function hideTeams(teamRow: Element, childTeams: NodeListOf<Element>) {
  recursiveHideChildTeams(childTeams)
  teamRow.classList.remove('is-open')
  locked = false
}

function recursiveHideChildTeams(teams: NodeListOf<Element>) {
  for (const team of teams) {
    const childTeamSlug = team.getAttribute('data-team-slug')!
    const childTeams = document.querySelectorAll(`.js-child-team[data-parent-team-slug="${childTeamSlug}"]`)

    recursiveHideChildTeams(childTeams)

    team.classList.remove('is-open')
    /* eslint-disable-next-line github/no-d-none */
    team.classList.add('d-none')
  }
}

on('click', '.js-show-more-child-teams', async function (event) {
  if (locked) return
  locked = true

  const target = event.currentTarget
  const teamRow = target.closest<HTMLElement>('.js-team-row')!
  const url = target.getAttribute('data-child-team-url')!
  const parentNode = teamRow.parentNode

  const html = await fetchSafeDocumentFragment(document, url)
  teamRow.before(html)
  if (parentNode) {
    parentNode.removeChild(teamRow)
  }
  locked = false
})
