import {attr, controller, target} from '@github/catalyst'
import {SinglePageWizardStepContentBase} from '../github/single-page-wizard/step-content-base'
import type {StepChangeResult, WizardState} from '../github/single-page-wizard/wizard'

@controller
class PersonalAccessTokensFeatureEnrollmentElement extends SinglePageWizardStepContentBase {
  @attr declare errorMessage: string
  @target declare form: HTMLFormElement

  override async onNextClick(): Promise<StepChangeResult<WizardState>> {
    let response

    try {
      response = await fetch(this.form.action, {
        method: this.form.method,
        body: new FormData(this.form),
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
    } catch {
      // Ignore network errors
    }

    if (response && !response.ok) {
      let errorMsg = this.errorMessage

      try {
        const jsonResponse = await response.json()

        if (jsonResponse.message) {
          errorMsg = jsonResponse.message
        }
      } catch {
        // Ignore JSON errors
      }

      this.setError(errorMsg)
      return {proceed: false}
    }

    return {proceed: true}
  }
}
