import {onInput} from '@github-ui/onfocus'
import {fire} from 'delegated-events'

function parameterizeName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // trim invalid characters
    .replace(/\s/g, '-') // convert spaces to hyphens
}

// auto update the slug input if needed
onInput('.js-business-name', function (event) {
  const nameInput = event.target as HTMLInputElement

  const slugInput = document.querySelector('.js-business-slug[data-auto-update="true"]')
  if (!(slugInput instanceof HTMLInputElement)) return

  slugInput.value = parameterizeName(nameInput.value)
  fire(slugInput, 'input') // remove once auto-check-element is updated to only validate on blur (currently behind feature flag)
  fire(slugInput, 'blur') // Manually trigger blur event to trigger auto-check-element, which now only runs on blur
})

onInput('.js-business-slug', function (event) {
  const slugInput = event.target as HTMLInputElement
  const slugValue = slugInput.value
  const hasEmptySlugValue = slugValue.trim() === ''
  slugInput.setAttribute('data-auto-update', `${hasEmptySlugValue}`)
  // sync icon is only used in stafftools
  const icon = document.querySelector<HTMLElement>('.js-name-slug-sync-icon')
  if (icon) {
    if (hasEmptySlugValue) {
      icon.removeAttribute('hidden')
    } else {
      icon.setAttribute('hidden', 'hidden')
    }
  }
})
