import {TemplateInstance} from '@github/template-parts'
import {on} from 'delegated-events'
import {parseHTML} from '@github-ui/parse-html'

// Re-render the partial (or show an error) when the following forms are submitted:
// - the add user form
// - the remove user form ('x')
on('submit', 'form[data-codespaces-settings-remote-form]', async function (event) {
  event.preventDefault()
  const form = event.currentTarget as HTMLFormElement

  // Make the request
  const response = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {Accept: 'text/fragment+html'},
  })

  // 'codespaces_settings_controller.rb#add_user' provides an error message in response to
  // specific classes of error. Only update the DOM in response to known server errors, to
  // avoid rendering a stack trace or other undesirable data.
  const knownServerStatusCodes = [400, 409, 500]

  // Retrieve the partial from the response and re-render it
  if (response.status <= 400 || knownServerStatusCodes.includes(response.status)) {
    const partial = parseHTML(document, await response.text())
    const container = document.querySelector('.js-settings-partial')
    container?.parentElement?.replaceChild(partial, container)
  }

  // Show an error message (if request fails)
  if (response.status >= 400) {
    // prettier-ignore
    // eslint-disable-next-line i18n-text/no-en
    const message = document.querySelector<HTMLInputElement>('.js-flash-error')?.value || 'An unknown error occurred.'
    if (message) {
      const template = document.querySelector<HTMLTemplateElement>('template.js-flash-template')!
      template.after(new TemplateInstance(template, {className: 'flash-error', message}))
    }
  }
})

// we need to implement data-autosubmit manually so we can set the `Accept` header
on('submit', 'form[data-codespaces-trusted-repos-form]', async function (event) {
  event.preventDefault()
  const form = event.currentTarget as HTMLFormElement
  const data = new FormData(form)
  form.querySelector('input[type="checkbox"]')?.setAttribute('disabled', 'disabled')

  const response = await fetch(form.action, {
    method: form.method,
    body: data,
    headers: {Accept: 'text/fragment+html'},
  })

  const target = form.closest('[data-replace-remote-form-target]') as HTMLElement
  const partial = parseHTML(document, await response.text())
  target.replaceWith(partial)
})
