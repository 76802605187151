import {controller, target} from '@github/catalyst'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import type AutocompleteElement from '@github/auto-complete-element'
import sudo from '@github-ui/sudo'

@controller
export class OrgTransformStepOneElement extends HTMLElement {
  @target declare container: HTMLElement
  @target declare summary: HTMLElement
  @target declare inputsWrapper: HTMLElement
  @target declare standardTermsRadio: HTMLInputElement
  @target declare businessTermsRadio: HTMLInputElement
  @target declare businessNameWrapper: HTMLElement
  @target declare editButton: HTMLElement
  @target declare cancelButton: HTMLElement

  connectedCallback() {
    if (this.businessTermsRadio?.checked) {
      this.businessNameWrapper.classList.remove('has-removed-contents')
    }
  }

  toggleTosRadio(event: Event) {
    if (event.currentTarget === this.standardTermsRadio) {
      this.businessNameWrapper.classList.add('has-removed-contents')
    } else if (event.currentTarget === this.businessTermsRadio) {
      this.businessNameWrapper.classList.remove('has-removed-contents')
    }
  }

  edit(event: Event) {
    if (event.currentTarget === this.editButton) {
      this.editButton.hidden = true
      this.summary.hidden = true
      this.inputsWrapper.hidden = false

      const stepTwoContainer = document.querySelector<HTMLElement>('.js-org-transform-step-two-container')
      if (stepTwoContainer) {
        const stepTwoBody = stepTwoContainer.querySelector<HTMLElement>('.js-org-transform-step-two-body')
        if (stepTwoBody) {
          if (stepTwoBody.getAttribute('data-step-two-complete') === 'true') {
            stepTwoBody.hidden = true
          } else {
            stepTwoBody.classList.add('has-removed-contents')
          }
        }

        const stepTwoEditButton = stepTwoContainer.querySelector<HTMLElement>('.js-org-transform-step-two-edit-btn')
        if (stepTwoEditButton) {
          stepTwoEditButton.hidden = true
        }
      }

      const stepThreeBody = document.querySelector<HTMLElement>('.js-org-transform-step-three-body')
      if (stepThreeBody) {
        stepThreeBody.classList.add('has-removed-contents')
      }

      const stepsCompleteInput = document.querySelector<HTMLInputElement>('.js-transform-steps-complete')!
      stepsCompleteInput.value = 'false'
    }
  }

  cancel(event: Event) {
    if (event.currentTarget === this.cancelButton) {
      this.editButton.hidden = false
      this.summary.hidden = false
      this.inputsWrapper.hidden = true

      const stepTwoContainer = document.querySelector<HTMLElement>('.js-org-transform-step-two-container')
      if (stepTwoContainer) {
        const stepTwoBody = stepTwoContainer.querySelector<HTMLElement>('.js-org-transform-step-two-body')
        if (stepTwoBody) {
          if (stepTwoBody.getAttribute('data-step-two-complete') === 'true') {
            stepTwoBody.hidden = false
          } else {
            stepTwoBody.classList.remove('has-removed-contents')
          }
        }

        const stepTwoEditButton = stepTwoContainer.querySelector<HTMLElement>('.js-org-transform-step-two-edit-btn')
        if (stepTwoEditButton) {
          stepTwoEditButton.hidden = false
        }
      }

      const stepThreeBody = document.querySelector<HTMLElement>('.js-org-transform-step-three-body')
      if (stepThreeBody) {
        if (stepThreeBody.getAttribute('data-step-two-complete') === 'true') {
          stepThreeBody.classList.remove('has-removed-contents')
        }
      }
    }
  }
}

@controller
export class OrgTransformStepTwoElement extends HTMLElement {
  @target declare container: HTMLElement
  @target declare autoComplete: AutocompleteElement
  @target declare autoCompleteWrapper: HTMLElement
  @target declare editButton: HTMLElement
  @target declare selectOwnerTitle: HTMLElement
  @target declare actionButtons: HTMLElement
  @target declare cancelButton: HTMLElement

  connectedCallback() {
    if (this.autoComplete) {
      this.autoComplete.addEventListener('auto-complete-change', this.handleAutoCompleteChangeEvent)
      if (this.autoComplete.value) {
        this.autoComplete.dispatchEvent(new Event('auto-complete-change'))
      }
    }
  }

  disconnectedCallback(): void {
    if (this.autoComplete) {
      this.autoComplete.removeEventListener('auto-complete-change', this.handleAutoCompleteChangeEvent)
    }
  }

  edit(event: Event) {
    if (event.currentTarget === this.editButton) {
      this.editButton.hidden = true
      this.selectOwnerTitle.hidden = false
      this.actionButtons.hidden = false

      this.autoCompleteWrapper.classList.remove('has-removed-contents')

      const ownerRemoveButtonWrapper = document.querySelector<HTMLElement>('.js-team-remove-user-wrapper')
      if (ownerRemoveButtonWrapper) {
        ownerRemoveButtonWrapper.hidden = false
      }

      const stepThreeBody = document.querySelector<HTMLElement>('.js-org-transform-step-three-body')
      if (stepThreeBody) {
        stepThreeBody.classList.add('has-removed-contents')
      }

      const stepsCompleteInput = document.querySelector<HTMLInputElement>('.js-transform-steps-complete')!
      stepsCompleteInput.value = 'false'
    }
  }

  cancel(event: Event) {
    if (event.currentTarget === this.cancelButton) {
      this.editButton.hidden = false
      this.selectOwnerTitle.hidden = true
      this.actionButtons.hidden = true

      this.autoCompleteWrapper.classList.add('has-removed-contents')

      const ownerRemoveButtonWrapper = document.querySelector<HTMLElement>('.js-team-remove-user-wrapper')
      if (ownerRemoveButtonWrapper) {
        ownerRemoveButtonWrapper.hidden = true
      }

      const stepThreeBody = document.querySelector<HTMLElement>('.js-org-transform-step-three-body')
      if (stepThreeBody) {
        stepThreeBody.classList.remove('has-removed-contents')
      }
    }
  }

  async handleAutoCompleteChangeEvent(event: Event): Promise<void> {
    const autoComplete = event.target as AutocompleteElement
    if (!autoComplete.value) {
      return
    }

    const button = event.currentTarget as HTMLButtonElement
    const container = button.closest<HTMLElement>('.js-team-add-user-form')!
    const input = container.querySelector<HTMLInputElement>('#choose-org-owner')!

    // When the autocomplete element value is set programmatically on load, the input value is not updated.
    const hideOwnerRemoveButton = !input.value
    const user = autoComplete.value
    input.value = ''
    const logins = document.querySelector<HTMLElement>('.js-team-user-logins')!

    // User already added to the organization.
    for (const el of logins.querySelectorAll('li')) {
      if (el.getAttribute('data-login') === user) {
        return
      }
    }

    const sudoPassed = await sudo()
    if (!sudoPassed) return

    const url = new URL(container.getAttribute('data-template-url') || '', window.location.origin)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('member', user)
    params.append('new_org_transform', '1')
    params.append('hide_remove_button', hideOwnerRemoveButton ? 'true' : 'false')
    url.search = params.toString()

    input.focus()
    const fragment = await fetchSafeDocumentFragment(document, url.toString())
    logins.appendChild(fragment)
    for (const el of document.querySelectorAll<HTMLInputElement>('.js-login-field')) {
      el.disabled = false
    }
    container.classList.add('has-removed-contents')
    autoComplete.hidden = false
  }
}

@controller
export class OrgTransformStepThreeElement extends HTMLElement {
  @target declare container: HTMLElement
  @target declare checkbox: HTMLInputElement

  toggleFormComplete(event: InputEvent) {
    const input = event.currentTarget as HTMLInputElement
    if (input !== this.checkbox) {
      return
    }

    const stepsCompleteInput = document.querySelector<HTMLInputElement>('.js-transform-steps-complete')!
    stepsCompleteInput.value = input.checked ? 'true' : 'false'
  }
}
