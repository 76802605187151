import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {on} from 'delegated-events'
import sudo from '@github-ui/sudo'

// Remove user from the owners team.
on('click', '.js-team-remove-user', function (event) {
  event.preventDefault()

  for (const el of document.querySelectorAll<HTMLElement>('.js-team-add-user-form')) {
    el.classList.remove('has-removed-contents')
  }

  const input = document.querySelector<HTMLInputElement>('.js-team-add-user-name')
  if (input) {
    input.focus()
  }
  event.currentTarget.closest<HTMLElement>('li')!.remove()
})

// Add user to the owners team.
on('click', '.js-team-add-user-button', function (event) {
  event.preventDefault()
  addUser(event.currentTarget)
})

async function addUser(button: Element) {
  const container = button.closest<HTMLElement>('.js-team-add-user-form')!
  const input = container.querySelector<HTMLInputElement>('.js-team-add-user-name')!
  const user = input.value
  input.value = ''
  const logins = document.querySelector<HTMLElement>('.js-team-user-logins')!

  // User already added to the organization.
  for (const el of logins.querySelectorAll('li')) {
    if (el.getAttribute('data-login') === user) {
      return
    }
  }

  const sudoPassed = await sudo()
  if (!sudoPassed) return

  const url = new URL(container.getAttribute('data-template-url') || '', window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('member', user)
  url.search = params.toString()

  input.focus()

  const fragment = await fetchSafeDocumentFragment(document, url.toString())
  logins.appendChild(fragment)
  for (const el of document.querySelectorAll<HTMLInputElement>('.js-login-field')) {
    el.disabled = false
  }
  container.classList.add('has-removed-contents')
}

on('click', '.js-rename-owners-team-next-btn', function () {
  const content = document.querySelector<HTMLElement>('.js-rename-owners-team-about-content')!
  content.hidden = !content.hidden
  const form = document.querySelector<HTMLElement>('.js-rename-owners-team-rename-form')!
  form.hidden = !form.hidden
})

on('click', '.js-repo-select-form input[name=repo_id]', async event => {
  const input = event.currentTarget as HTMLInputElement
  const label = document.getElementById('js-selected-repository-name')!

  const text = input.closest('.select-menu-item-text')!.querySelector('.js-repository-name')!.textContent
  const button = document.getElementById('js-submit-button') as HTMLButtonElement

  if (button) {
    button.disabled = false
  }

  if (text && label) {
    label.textContent = text
  }
})
