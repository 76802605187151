import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'

function textForMembers(details: HTMLElement): string | null {
  const teamMembers = details.querySelectorAll<HTMLInputElement>('input.js-team-member:checked')

  if (teamMembers.length === 0) {
    return null
  } else if (teamMembers.length <= 3) {
    return Array.from(teamMembers)
      .map(input => input.getAttribute('data-team-member-text')!)
      .join(', ')
  } else {
    return `${teamMembers.length} members selected`
  }
}

function setTeamMemberButton(details: HTMLElement) {
  const summary = details.querySelector<HTMLElement>('.js-team-member-summary')!
  const text = summary.querySelector<HTMLElement>('.js-team-member-summary-text')!
  text.textContent = textForMembers(details) || summary.getAttribute('data-default-label')!
}

on(
  'details-menu-selected',
  '.js-team-member-menu',
  function (event) {
    const details = event.currentTarget.closest<HTMLElement>('.js-team-member-picker')!
    setTeamMemberButton(details)
  },
  {capture: true},
)

observe('.js-team-member-summary', function (element) {
  const details = element.closest<HTMLElement>('.js-team-member-picker')!
  setTeamMemberButton(details)
})
