import {controller, target} from '@github/catalyst'

const fallbackFormErrorMessage = 'Oops, something went wrong.'

@controller
class CodespacesSpendingLimitModalElement extends HTMLElement {
  @target declare budgetGroupInput: HTMLInputElement
  @target declare closeModalButton: HTMLButtonElement
  @target declare errorMessageElement: HTMLElement
  @target declare errorMessageElementText: HTMLSpanElement
  @target declare spendingLimitAmountInput: HTMLInputElement
  @target declare limitSpendingRadioInput: HTMLInputElement
  @target declare unlimitedSpendingRadioInput: HTMLInputElement
  @target declare updateLimitSubmitButton: HTMLButtonElement
  @target declare codespacesAccessAlertElement: HTMLElement

  connectedCallback() {
    document.body.classList.toggle('overflow-hidden', true)
  }

  closeModal(): void {
    this.hidden = true
    document.body.classList.toggle('overflow-hidden', false)
  }

  disableSubmitButton(): void {
    this.updateLimitSubmitButton.disabled = true
  }

  enableSubmitButton(): void {
    this.updateLimitSubmitButton.disabled = false
  }

  handleLimitSpendingRadioInputClick(): void {
    this.unlimitedSpendingRadioInput.checked = false
    this.spendingLimitAmountInput.disabled = false
  }

  handleUnlimitedSpendingRadioInputClick(): void {
    this.limitSpendingRadioInput.checked = false
    this.spendingLimitAmountInput.disabled = true
    this.hideCodespaceAccessAlert()
  }

  handleSpendingLimitChange({target: input}: {target: HTMLInputElement}): void {
    if (valueIsNotPositive(input.value)) {
      return this.showCodespacesAccessAlert()
    }

    this.hideCodespaceAccessAlert()
  }

  showCodespacesAccessAlert(): void {
    this.codespacesAccessAlertElement.hidden = false
  }

  hideCodespaceAccessAlert(): void {
    this.codespacesAccessAlertElement.hidden = true
  }

  showErrorMessageElement(): void {
    this.errorMessageElement.hidden = false
  }

  hideErrorMessageElement(): void {
    this.errorMessageElement.hidden = true
  }

  async submitForm(event: Event): Promise<void> {
    event.preventDefault()
    this.disableSubmitButton()
    this.hideErrorMessageElement()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)
    formData.append('enforce_spending_limit', String(this.limitSpendingRadioInput.checked))
    formData.append('spending_limit', this.spendingLimitAmountInput.value)

    try {
      const response = await fetch(form.action, {
        method: 'PUT',
        body: formData,
        headers: {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      })

      if (response.status === 200) {
        this.closeModal()
        return
      }

      const json = await response.json()

      this.errorMessageElementText.textContent = json.error ? json.error : fallbackFormErrorMessage
      this.showErrorMessageElement()
      this.enableSubmitButton()
    } catch (error) {
      this.errorMessageElementText.textContent = fallbackFormErrorMessage
      this.showErrorMessageElement()
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      if (!error.response) throw error
    }
  }
}

function valueIsNotPositive(value: string): boolean {
  return typeof value === 'undefined' || value === null || value === '' || value === '0.0' || value === '0.00'
}
